import { DateTime } from "luxon";
import { toFinite } from "lodash";

import { QuarterConstants } from "../constants/QuarterConstants";
import { formatDayIndexToString } from "../helpers/DateHelpers";
import { deepUpdate } from "immupdate";
import moment from "moment";

export function formatDate(date) {
  let d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day
  return [year, month, day].join('-');
}

export function getDaysCount(month, year) {
  return new Date(year, month, 0).getDate();
}

export function getCurrentYear() {
  return DateTime.local().year;
}

export function getCurrentMonth() {
  return DateTime.local().month;
}

export function getCurrentWeekNumber() {
  return DateTime.now().weekNumber;
}

export function isCurrentDate(date) {
  if (!date) {
    return false;
  }

  const nowDateTime = DateTime.now();
  const dateTime = normalizeDateTime(date);

  return nowDateTime.startOf("day").toMillis() === dateTime.startOf("day").toMillis();
}

export function getWeekDates(date) {
  const firstDate = getFirstWeekDay(date);

  const result = [];

  for (let i = 0; i < 7; i++) {
    result[formatDayIndexToString(i + 1)] = {
      date: firstDate.plus({ days: i }),
      plans: [],
    };
  }

  return result;
}

export function getWeekDateRange(date) {
  return {
    fromDate: getFirstWeekDay(date).toFormat("yyyy-MM-dd"),
    toDate: getLastWeekDay(date).toFormat("yyyy-MM-dd"),
  };
}

export function normalizeDateTime(date) {
  if (DateTime.isDateTime(date)) {
    return date;
  }

  if (date) {
    return DateTime.fromJSDate(date);
  }

  return DateTime.now();
}

export function getPrevDate(date) {
  const dateTime = normalizeDateTime(date);

  return dateTime.minus({ days: 1 });
}

export function getNextDate(date) {
  const dateTime = normalizeDateTime(date);

  return dateTime.plus({ days: 1 });
}

export function getFirstWeekDay(date) {
  const dateTime = normalizeDateTime(date);

  return dateTime.weekday > 1 ? dateTime.minus({ days: dateTime.weekday - 1 }) : dateTime;
}

export function getLastWeekDay(date) {
  const dateTime = normalizeDateTime(date);

  return dateTime.weekday < 7 ? dateTime.plus({ days: 7 - dateTime.weekday }) : dateTime;
}

export function getWeekNumbersFromDateRange(from, to) {
  const toDateTime = DateTime.isDateTime(to) ? to : DateTime.fromJSDate(to);
  const fromDateTime = DateTime.isDateTime(from) ? from : DateTime.fromJSDate(from);

  const dates = Array(Math.abs(fromDateTime.diff(toDateTime, "days").toObject().days - 1)).fill(0);

  return dates.reduce((acc, item, index) => {
    const dateTime = fromDateTime.plus({ days: index });

    const weekNumber = dateTime.weekNumber;
    const weekDay = formatDayIndexToString(dateTime.weekday);

    return deepUpdate(acc)
      .at(weekNumber)
      .withDefault({})
      .at(weekDay)
      .withDefault({})
      .set({
        weekNumber,
        date: dateTime.toFormat("yyyy-MM-dd"),
      });
  }, {});
}

export function getDateByWeekNumber(weekNumber) {
  return DateTime.fromObject({ weekNumber });
}

export function getWeekNumberByDate(date) {
  const dateTime = normalizeDateTime(date);

  return dateTime.weekNumber;
}

export function getCurrentQuarter() {
  const localDate = DateTime.now();

  const year = getCurrentYear();

  const quarterIndex = QuarterConstants.findIndex((x) => {
    const fromArr = x.from.split(".");
    const toArr = x.to.split(".");

    const localMillis = localDate.toMillis();
    const fromCurrentMillis = localDate
      .set({ year, day: toFinite(fromArr[0]), month: toFinite(fromArr[1]) })
      .startOf("day")
      .toMillis();
    const toCurrentMillis = localDate
      .set({ year, day: toFinite(toArr[0]), month: toFinite(toArr[1]) })
      .endOf("day")
      .toMillis();

    return localMillis >= fromCurrentMillis && localMillis <= toCurrentMillis;
  });

  return quarterIndex + 1;
}

export function isActiveQuarter({ quarter, year, month, day }) {
  if (QuarterConstants.length < quarter || quarter <= 0) {
    return false;
  }

  const localDate = DateTime.now();

  const { from, to } = QuarterConstants[quarter - 1];

  const fromArr = from.split(".");
  const toArr = to.split(".");

  const localMillis = localDate.set({ year, month, day }).toMillis();
  const fromCurrentMillis = localDate
    .set({ year, month: fromArr[1], day: fromArr[0] })
    .startOf("day")
    .toMillis();
  const toCurrentMillis = localDate
    .set({ year, month: toArr[1], day: toArr[0] })
    .endOf("day")
    .toMillis();

  return localMillis >= fromCurrentMillis && localMillis <= toCurrentMillis;
}

export function getQuarterDates({ quarter, year }) {
  if (QuarterConstants.length < quarter || quarter <= 0 || !quarter) {
    return false;
  }

  const localDate = DateTime.now();

  const { from, to } = QuarterConstants[quarter - 1];

  const fromArr = from.split(".");
  const toArr = to.split(".");

  const fromDate = localDate
    .set({ year: year || getCurrentYear(), month: fromArr[1], day: fromArr[0] })
    .startOf("day");
  const toDate = localDate
    .set({ year: year || getCurrentYear(), month: toArr[1], day: toArr[0] })
    .endOf("day");

  return { to: toDate, from: fromDate };
}

export const currentQuarter = (list,navigation) => {
  const date= new Date().valueOf();
  let temp;
  list.forEach(item => {
    let start= new Date(item.start).valueOf()
    let end= new Date(item.end).valueOf()
    if(start<=date && end>=date){
      temp = item.id
      navigation.replaceQuery({curQuarter:item.id})
      return item.id
    }
  })
  return  temp
}

export const currentYear = (list) => {
  const date= new Date().valueOf();
  let temp;
  list.forEach(item => {
    let start= new Date(item.start_date).valueOf()
    let end= new Date(item.end_date).valueOf()
    if(start<=date && end>=date){
      temp=item
      return item
    }
  })
  return temp
}

export const simpleDaterFormat = (date) => {
  const temp = moment(date) || moment();
  return date
      ? temp.format('DD-MM-YYYY')
      : '-'
}
