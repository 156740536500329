import createReducer from "../helpers/createReducer";
import {END_LESSON, START_LESSON, SET_ACTIVE_LESSON, SET_MARK_PAYLOAD, RESET_MARK_PAYLOAD, SET_ABSENCE_ITEM_PAYLOAD} from "../actionTypes/currentLessonPathTpes";


const defaultState = {
    lessonPath:"",
    isActiveLesson: false,
    markPayload: {},
    absenceItem: {}
}

const reducers = {
    [START_LESSON](state, {lessonPath}){
        localStorage.setItem('lessonPath',lessonPath)
        return {
            ...state,
            lessonPath
        }
    },
    [END_LESSON](state){
        localStorage.removeItem("lessonPath")
        return{
            lessonPath:''
        }
    },
    [SET_ACTIVE_LESSON](state, { isActive }) {
        return {
            ...state,
            isActiveLesson: isActive
        }
    },
    [SET_MARK_PAYLOAD](state, { data }) {
        return {
            ...state,
            markPayload: { show: !state.isActiveLesson, data }
        }
    },
    [RESET_MARK_PAYLOAD](state) {
        return {
            ...state,
            markPayload: {}
        }
    },
    [SET_ABSENCE_ITEM_PAYLOAD](state, { data }) {
        return {
            ...state,
            absenceItem: data
        }
    }
}

export default createReducer(defaultState, reducers);
