import {
    END_LESSON, 
    START_LESSON, 
    SET_ACTIVE_LESSON, 
    SET_MARK_PAYLOAD, 
    RESET_MARK_PAYLOAD, 
    SET_ABSENCE_ITEM_PAYLOAD,
} from "../actionTypes/currentLessonPathTpes";


export const initLesson = () => {
    const lessonPath = localStorage.getItem("lessonPath")

    return (dispatch) =>{
        if(lessonPath){
            dispatch({type:START_LESSON,lessonPath});
            return true
        }else {
            return false
        }

    }
}

export const startCurrentLesson = (lessonPath) =>{
    return {
        type:START_LESSON,
        lessonPath
    }
}

export const endCurrenLesson = () => {
    return {
       type:END_LESSON
    }
}

export const setActiveLesson = (isActive) => {
    return (dispatch) => {
        dispatch({
            type: SET_ACTIVE_LESSON,
            isActive
        })
    }
}

export const setMarkPayload = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_MARK_PAYLOAD,
            data
        })
    }
}

export const resetMarkPayload = () => {
    return (dispatch) => {
        dispatch({
            type: RESET_MARK_PAYLOAD,
        })
    }
}

export const setAbsenceItem = (data) => {
    return (dispatch) => {
        dispatch({
            type: SET_ABSENCE_ITEM_PAYLOAD,
            data
        })
    }
}